<template>
  <div v-if="loading" class="all-height d-flex align-center justify-center">
    <div class="flex-grow-1">
      <v-row>
        <v-col cols="12" md="12">
          <div class="text-center">
            <!-- <img src="/img/conserve/logotext-no-bg.webp" class="logo-image mb-4" alt="consark-logo-image"/> -->
            <div class="mb-4 subtitle-1">Loading Your Project List</div>
            <loader />
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
  <div v-else class="all-height d-flex justify-center">
    <div class="all-width">
      <div class="d-flex align-center">
        <v-spacer></v-spacer>
        <v-btn class="shadow-off mb-4" text @click="refreshData()"><v-icon left>mdi-refresh</v-icon>Refresh</v-btn>
      </div>
      <v-row>
        <v-col cols="12" sm="6" md="4" v-for="(v, k) in listdata" :key="k">
          <v-card link @click="$nova.gotoLink({path: '/pbc/requestee/project/'+client+'/'+code+'/'+v._id})">
            <v-card-text class="pa-0">
              <div class="d-flex align-center pt-2 px-4">
                <div class="subtitle-1 font-weight-bold" style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">
                  {{v.name || 'No Name'}}
                </div>
              </div>
              <div class="pb-3 px-4 d-flex align-center grey--text text--darken-2">
                <span class="pr-2 font-weight-bold">Due:</span>
                <span>{{$nova.formatDate(v.duedate)}}</span>
                <v-icon>mdi-circle-small</v-icon>
                <span label v-if="(Math.floor((new Date(v.duedate) - new Date())/(60*60*24*1000)) < 0)" class="error--text">{{( Math.floor((new Date(v.duedate) - new Date())/(60*60*24*1000))*-1)}} days overdue</span>
                <span label v-else class="info--text">{{( Math.floor((new Date(v.duedate) - new Date())/(60*60*24*1000)))}} days remaining</span>
                <v-spacer></v-spacer>
                <!-- <v-chip label class="caption ml-1 px-2" :color="(Math.floor((new Date(v.duedate) - new Date())/(60*60*24*1000)) < 0)?'error':'info'" small>{{ Math.floor((new Date(v.duedate) - new Date())/(60*60*24*1000))}} Days</v-chip> -->
              </div>
              <div class="py-1 px-4 grey lighten-3 d-flex align-center">
                  <span>Total Requests</span>
                  <v-spacer></v-spacer>
                  <span class="title pr-2">{{((v.summary || {}).requested || 0) + ((v.summary || {}).approved || 0) + ((v.summary || {}).cancelled || 0) + ((v.summary || {}).pending || 0)}}</span>
              </div>
              <div class="py-3 px-4">
                <div v-if="(((v.summary || {}).requested || 0) + ((v.summary || {}).pending || 0) + ((v.summary || {}).approved || 0) + ((v.summary || {}).cancelled || 0)) > 0">
                  <canvas :ref="'pbc'+v._id" style="max-height:120px"></canvas>
                </div>
                <div v-else class="py-5 text-center">
                  <div class="grey--text text--lighten-1 title pb-2">No Request added</div>
                  <v-btn color="primary" small @click="$nova.gotoLink({path: '/pbc/project/view/'+v._id, hash: 'add'})"><v-icon left>mdi-plus</v-icon>Add Request</v-btn>
                </div>
                
              </div>
            </v-card-text>
          </v-card>
        </v-col>

      </v-row>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import pageHeader from '@/components/pageHeader'
// import managelist from '@/components/ManageList'
import loader from '@/views/common/Loader';
import {Chart, PolarAreaController,RadialLinearScale,ArcElement,Legend,Title,Tooltip,DoughnutController} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.register(PolarAreaController,RadialLinearScale,ArcElement,Legend,Title,Tooltip,DoughnutController,ChartDataLabels);

export default {
  name: 'pbc_project_list_requestee',
  // props: ['props'],
  data: function(){
    return {
      code: "",
      client: "",
      loading: false,
      listdata: [],
      charts: [],
      chartdata: {},
    }
  },
  components: {
  //   // pageHeader,managelist
    loader
  },
  created() {
    this.client = this.$route.params.client || "";
    this.$store.commit("setClient", this.client);
    this.$store.commit("headeraction", false);
    this.refreshData();
    this.$store.commit("addLoading");
    this.$nextTick(() => {
      let menu = [
        { product: true, title: 'Information Workflow', bgcolor: 'crisk' },
        {
          title: 'Dashboard', icon: 'mdi mdi-view-dashboard-outline', href: '/pbc/requestee/view/'+this.client+"/"+this.code,
        }
      ];
      this.$store.commit("removeLoading");          
      this.$store.commit("setNewMenu", menu);
      this.$store.commit("setCustomMenu", []);
    })
  },
  activated() {
    this.refreshData();
  },
  methods: {
    refreshData() {
      this.code = this.$route.params.code || "";
      this.getData();
    },
    getData() {
      this.loading = true;
      this.axios.post("/v2/pbc/project/getforrequestee/"+this.code+"/p").then(dt => {
        this.listdata = [];
        if(dt.data.status === "success"){
          if(dt.data.data.length > 0){
            this.listdata = dt.data.data || [];
            let cmenu = [
              { header: true, title: 'Projects' }
            ];
            for (let i = 0; i < this.listdata.length; i++) {
              const el = this.listdata[i];
              cmenu.push({title: el.name, icon: 'mdi mdi-format-list-group', href: '/pbc/requestee/project/'+this.client+"/"+this.code+'/'+el._id})
            }
            this.$store.commit("setCustomMenu", cmenu);
          }else this.$nova.gotoLink({path: '/error/404'});
        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
        this.setChart();
      })
    },
    getPBCChartData: function() {
      return {
        type: 'doughnut',
        data: {
          labels: ["Yet to Respond", "Waiting for Approval", "Closed", "Cancelled"],
          datasets: [
            {
              label: 'Count',
              data: [],
              backgroundColor: [],
              // datalabels: {
              //   anchor: 'center',
              //   backgroundColor: null,
              //   borderWidth: 0
              // },
            }
          ]
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'right',
            },
            datalabels: {
              color: 'white',
              display: function(context) {
                var dataset = context.dataset;
                var count = dataset.data.length;
                var value = dataset.data[context.dataIndex];
                return ((value/count) || 0) > 0.1;
              },
              font: {
                weight: 'bold'
              },
            }
          }
        }
      }
    },
    setChart: function(){
      this.$nextTick(function () {
        console.log(this.$refs);
        for (let i = 0; i < this.listdata.length; i++) {
          const el = {...this.listdata[i]};
          if((((el.summary || {}).requested || 0) + ((el.summary || {}).pending || 0) + ((el.summary || {}).approved || 0) + ((el.summary || {}).cancelled || 0)) > 0){
            let canvasid = 'pbc'+el._id; 
            if(this.charts[canvasid]) this.charts[canvasid].destroy();
            this.chartdata[canvasid] = this.getPBCChartData();
            this.chartdata[canvasid].data.datasets = [{
              label: 'Count',
              data: [((el.summary || {}).requested || 0), ((el.summary || {}).pending || 0), ((el.summary || {}).approved || 0), ((el.summary || {}).cancelled || 0)],
              backgroundColor: this.$nova.getColors(5, 0.5).slice(1,5),
            }];
            console.log(this.$refs[canvasid][0]);
            this.charts[canvasid] = new Chart((this.$refs[canvasid] || [])[0].getContext("2d"), this.chartdata[canvasid]);
          }
        }
      });
    },
  },
  computed: {
  },
  watch: {
  }
}
</script>
